$color-blue-l30: #0f2733;
$color-blue-l50: #1a3d4f;
$color-blue-l75: #2e5a6e;
$color-blue-l80: #3f6f7f;
$color-blue-l90: #5c8a9e;
$color-blue-l95: #7aa5b5;
$color-blue: $color-blue-l50;

$color-lightblue-l50: #0f2733;
$color-lightblue-l65: #2e5a6e;

$color-electric-l30: #0b6eb5;
$color-electric-l50: #1a8bcb;
$color-electric-l75: #2e9ed1;

$color-white: hsl(0, 0%, 100%);
$color-white-op70: hsla(0, 0%, 100%, 0.7);

$color-grey-l95: hsl(0, 0%, 95%);

$color-coolgrey-l98: hsl(230, 5%, 98%);
$color-coolgrey-l96: hsl(230, 5%, 96%);
$color-coolgrey-l90: hsl(230, 5%, 90%);
$color-coolgrey-l80: hsl(230, 5%, 80%);
$color-coolgrey-l70: hsl(230, 5%, 70%);
$color-coolgrey-l60: hsl(230, 5%, 60%);
$color-coolgrey-l50: hsl(230, 5%, 50%);
$color-coolgrey-l25: hsl(230, 5%, 25%);
$color-coolgrey-l17: hsl(230, 5%, 17%);

$color-green: hsl(157, 78%, 44%);
$color-lightgreen: hsl(157, 60%, 65%);

$color-red: hsl(8, 93%, 48%);
$color-orange: #c76244;
$color-yellow: hsl(45, 97%, 50%);

// Numberplate
$color-numberplate-blue: hsl(249, 87%, 40%);
$color-numberplate-yellow: hsl(44, 98%, 59%);

$color-status-success: $color-green;
$color-status-notice: $color-blue-l75;
$color-status-warning: $color-orange;
$color-status-error: $color-red;

$color-status-list: (
  success: $color-status-success,
  notice: $color-status-notice,
  warning: $color-status-warning,
  error: $color-status-error
);
